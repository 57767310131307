import { useStaticQuery, graphql } from 'gatsby';

const useWorkList = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulProject(sort: { order: DESC, fields: name }) {
          edges {
            node {
              name      
              description {
                id          
                childMarkdownRemark {
                    html
                }
              }
              techno              
              heroImage {
                fluid(maxWidth: 960) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );
  return data;
};

export default useWorkList;
