import { FaLinkedinIn, FaEnvelope } from 'react-icons/fa';

const content = {
  title: 'About.',
  subtitle: 'I build apps and websites.',  
  links: [
    {
      to: 'https://fr.linkedin.com/in/david-jean-0aa6902b',
      text: 'linkedin',
      Icon: FaLinkedinIn,
      newTab: true,
    },
    {
      to: 'mailto:davidjean.labs@gmail.com',
      text: 'Contact me',
      Icon: FaEnvelope,
      newTab: true,
    },
  ],
};

export default content;
