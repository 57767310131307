import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Work } from 'components';

import useWorkList from './useWorklists';
import styles from './style';
/* */
const WorkList = ({ classes }) => {
  const { allContentfulProject } = useWorkList();

  return (
    <div className={classes.container}>
      {allContentfulProject.edges.map(({ node }, index) => (
        <Work key={index} data={node} />
      ))}
    </div>
  );
};

export default withStyles(styles)(WorkList);
